import { Link, withIntl } from '../../i18n'
import { StaticQuery, graphql } from 'gatsby'
import {
  bannerIcon,
  downloadButton,
  feature,
  fluidImage,
  fullWidth,
  phone,
} from '../../fragments/fragments'

import Banner from '../../components/reusables/banner'
import Columns from '../../components/reusables/columns'
import { FormattedMessage } from 'react-intl'
import Hero from '../../components/reusables/hero'
import ImageText from '../../components/reusables/imageText'
import ImageTextHero from '../../components/reusables/imageTextHero'
import IndexMenu from '../../components/reusables/indexMenu'
import Layout from '../../components/layout'
import Metamask from '../../components/reusables/metamask'
import React from 'react'

const MetaMask = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <Metamask index={0} data={data} shade={'light'} />
    {/* <Columns index={0} data={data} shade={'grey'} /> */}
  </Layout>
)

export default withIntl(MetaMask)

export const imagesQuery = graphql`
  query {
    bannerCMT: file(relativePath: { eq: "hero/bannerCMT@3x.png" }) {
      ...bannerIcon
    }
    metamask: file(relativePath: { eq: "regular/Venus.png" }) {
      childImageSharp {
        fluid(maxWidth: 806) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    shortLastSection: file(relativePath: { eq: "hero/shortLastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
